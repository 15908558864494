<template>
    <div>
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="loadSuccess && !talent">
            很抱歉，您还未认证通过，请认证您的账号
        </van-notice-bar>
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="loadSuccess && talent && talent.status !== 3 && !talent.short_name">
            来飞网账号系统升级，请编辑您的常住地区
        </van-notice-bar>
<!--        <van-dropdown-menu v-if="loadSuccess && talent">-->
<!--            <van-dropdown-item title="设置" ref="item">-->
<!--                <van-switch-cell :value="setting.notice" @input="noticeChange" title="接收新订单微信通知" />-->
<!--            </van-dropdown-item>-->
<!--        </van-dropdown-menu>-->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item style="padding: 10px 10px 0;box-sizing: border-box;">
                <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/activity_mine.png" style="width: 100%;" @click="$router.push('/lottery')">
            </van-swipe-item>
        </van-swipe>
        <van-pull-refresh v-model="isLoading" @refresh="loadData(true)">
            <div class="container" v-if="loadSuccess && talent" style="padding: 0 0 150px;">
                <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                    <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;">
                        账户类型
                        <span v-if="talent.status === -1" style="float: right;color: #ee0a24;">{{talent.status_name}}</span>
                        <span v-if="talent.status === 0" style="float: right;color: #aaa;">{{talent.status_name}}</span>
                        <span v-if="talent.status === 1 && talent.intelligent" style="float: right;color: #07c160;display: flex;">
                            <span>达人用户</span>
                            <van-icon name="certificate" size="18" />
                        </span>
                        <span v-if="talent.status === 1 && !talent.intelligent" style="float: right;color: #07c160;display: flex;">
                            <span>普通用户</span>
                            <van-icon name="certificate" size="18" />
                        </span>
                        <span v-if="talent.status === 2" style="float: right;color: #ee0a24;">{{talent.status_name}}</span>
                        <span v-if="talent.status === 3" style="float: right;color: #aaa;">{{talent.status_name}}</span>
                    </div>
                    <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                        <van-cell title="账号昵称" :value="talent.account_name"/>
                        <van-cell title="账号等级" :value="talent.channel_name"/>
                        <van-cell title="申请时间" :value="talent.apply_time"/>
                        <van-cell title="常住地区" v-if="talent.short_name" :value="talent.short_name"/>
                        <van-cell title="认证时间" v-if="talent.status === 1 && talent.verify_time" :value="talent.verify_time"/>
                        <van-cell title="审核意见" v-if="talent.status !== 1 && talent.remark" :value="talent.remark"/>
                    </van-cell-group>
                    <div style="font-size: 14px;font-weight: bold;border-top: 1px solid #ebedf0;text-align: right;">
                        <span v-if="talent.status === 3" style="display:block;color: red;margin: 10px 20px 15px;">您的账号已被冻结<br>如有疑问请联系来飞网在线客服</span>
                        <span v-else-if="talent.status === 0" style="display:block;color: #aaa;margin: 10px 20px 15px;">正在审核</span>
                        <van-button style="padding: 0 20px;background: #e2ba7f;color: #fff;margin: 10px 20px;" round v-else
                                    size="small" @click="$router.push('/talent/apply')">编 辑</van-button>
                    </div>
                </div>
            </div>
            <div style="width: 100%;padding:16px 16px 200px;box-sizing: border-box;"  v-if="loadSuccess && !talent">
                <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" block @click="apply">申请认证</van-button>
            </div>
        </van-pull-refresh>
        <talent-tabbar-dialog :active="2" @navigate="navigate"></talent-tabbar-dialog>
        <phone-bind ref="phoneBind" @success="bindPhoneSuccess"></phone-bind>
    </div>
</template>

<script>
    import {ImagePreview, Notify, Toast} from 'vant';
    import PhoneBind from "../Common/Dialog/PhoneBind";
    import TalentTabbarDialog from "../Common/Dialog/TalentTabbarDialog";
    export default {
        name: "TalentList",
        components:{
            PhoneBind,
            TalentTabbarDialog,
        },
        data() {
            return {
                talent: {},
                isLoading: false,
                verified_phone: false,
                loadSuccess:false,
                // setting:{
                //     notice: false,
                // },
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            navigate(page){
                this.$router.replace(page);
            },
            loadData(loading = false) {
                let that = this;
                this.post('/api/client/talent/list', {}).then(result => {
                    if (result.code === 0) {
                        that.talent = result.data.talent;
                        that.verified_phone = result.data.verified_phone;
                        // that.setting.notice = result.data.notice;
                        that.isLoading = false;
                        if (loading) {
                            Notify({type: 'success', message: '刷新成功'});
                        }
                    }
                    this.loadSuccess = true;
                })
            },
            imagePreview(url) {
                ImagePreview({
                    images: [url],
                    closeable: true,
                    closeOnPopstate:true,
                });
            },
            apply(){
                // 检查当前用户是否绑定了手机号
                if(!this.verified_phone){
                    this.$refs.phoneBind.init();
                    return;
                }
                this.$router.push('/talent/apply');
            },
            bindPhoneSuccess(){
                this.verified_phone = true;
                this.apply();
            },
            // noticeChange(notice){
            //     let that = this;
            //     console.log(notice);
            //     Toast.loading({
            //         message: '正在修改...',
            //         forbidClick: true,
            //         duration: 0,
            //     });
            //     this.post('/api/client/talent/notice',{notice: notice}).then(result=>{
            //         Toast.clear();
            //         if(result.code === 0){
            //             that.setting.notice = notice;
            //         } else {
            //             that.setting.notice = !notice;
            //             Toast('修改失败，请稍后重试');
            //         }
            //     }).catch(e=>{
            //         that.setting.notice = !notice;
            //         Toast.clear();
            //         Toast('修改失败，请稍后重试');
            //     });
            // },
        },
    }
</script>

<style scoped>
    .container > div {
        margin: 10px;
        border: 1px solid #eee;
    }

    .list-cell .van-cell__title {
        flex: 0 0 75px;
    }

    .list-cell .van-cell__value {
        flex: 1 1 auto;
    }
</style>